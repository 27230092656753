import { TradeTradeKindChoices } from "../generated/graphql"

export const EXPENSE_KINDS = [
  { id: "flat", name: "Flat", short: "Rs" },
  { id: "amount_percent", name: "Amount Percent", short: "%" },
  { id: "quantity_per_unit", name: "Quantity Per Unit", short: "Per Unit" },
  {
    id: "deducted_quantity_per_unit",
    name: "Deducted Quantity Per Unit",
    short: "Per Unit",
  },
]

export const TRADE_STATUES = [
  { id: "draft", name: "Draft" },
  { id: "sent", name: "Sent" },
  { id: "verified", name: "Verified" },
  { id: "rejected", name: "Rejected" },
  { id: "in_progress", name: "In Progress" },
  { id: "complete", name: "Complete" },
]

export const TRADE_KINDS = [
  {
    id: TradeTradeKindChoices.ProForma.toLowerCase(),
    name: "Sauda Pavti",
  },
  {
    id: TradeTradeKindChoices.Booking.toLowerCase(),
    name: "Booking Patti",
  },
  {
    id: TradeTradeKindChoices.Final.toLowerCase(),
    name: "Hishob Patti",
  },
]
