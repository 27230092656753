import { authExchange } from "@urql/exchange-auth"
import { cacheExchange } from "@urql/exchange-graphcache"
import { createContext, useContext, useState } from "react"
import {
  createClient,
  dedupExchange,
  fetchExchange,
  makeOperation,
  Provider,
  subscriptionExchange,
} from "urql"
import { createClient as createWSClient } from "graphql-ws"
import { getLocalStorage } from "../pages/pavti/lib/utils"

type AuthConfig = {
  token: string
  refreshToken?: string
}
export const makeClient = () => {
  let result = window.location.pathname.split("/").filter(word => {
    try {
      return window.atob(word)
    } catch (error) {
      return false
    }
  })
  const pavtiToken = getLocalStorage(result[0] || "")?.token
  const wsClient = createWSClient({
    url: `wss://${import.meta.env.VITE_API_URL}/v1/graphql`,
    connectionParams: async () => {
      return {
        headers: {
          Authorization: `Bearer ` + pavtiToken,
          "x-hasura-role": "trade_consumer",
        },
      }
    },
  })

  return createClient({
    url: `https://${import.meta.env.VITE_API_URL}/v1/graphql`,
    exchanges: [
      dedupExchange,
      cacheExchange({}),
      authExchange<AuthConfig>({
        async getAuth({ authState }) {
          if (!authState) {
            const token = localStorage.getItem("token")
            if (token) {
              return { token, role: "manager" }
            } else if (pavtiToken) {
              return { token: pavtiToken, role: "trade_consumer" }
            } else {
              return { token: import.meta.env.VITE_JWT }
            }
          }
          localStorage.removeItem("token")
          return null
        },
        addAuthToOperation({ authState, operation }: any) {
          if (!authState || !authState?.token) {
            return operation
          }

          const fetchOptions =
            typeof operation.context.fetchOptions === "function"
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {}

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.token}`,
                ...(authState?.token === import.meta.env.VITE_JWT
                  ? null
                  : { "x-hasura-role": authState.role }),
              },
            },
          })
        },
        didAuthError({ error }) {
          return error.graphQLErrors.some(e => {
            return e.extensions.code === "invalid-jwt"
          })
        },
      }),
      subscriptionExchange({
        forwardSubscription: operation => ({
          subscribe: sink => ({
            unsubscribe: wsClient.subscribe(operation, sink),
          }),
        }),
      }),
      fetchExchange,
    ],
  })
}

type ClientState = {
  resetClient: () => void
}
const ClientContext = createContext<ClientState>({ resetClient: () => {} })

function ClientProvider({ children }) {
  const [client, setClient] = useState(makeClient())

  return (
    <ClientContext.Provider
      value={{
        resetClient() {
          setClient(makeClient())
        },
      }}
    >
      <Provider value={client}>{children}</Provider>
    </ClientContext.Provider>
  )
}

export const useClient = () => useContext(ClientContext)
export default ClientProvider
