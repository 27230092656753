import { Button, Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { CaretDown } from "phosphor-react"
import { useSearchParams } from "react-router-dom"

const TradeActions = () => {
  const [params, setParams] = useSearchParams()
  return (
    <Popover
      minimal
      arrow={false}
      className="p-0"
      trigger="mouseenter"
      interactive
      animation="fade"
      theme="light-border"
      placement="bottom"
      content={
        <Menu className="max-h-52">
          <MenuItem
            onClick={() => {
              params.set("update", "all")
              setParams(params)
            }}
          >
            Update Trades
          </MenuItem>
          <MenuItem
            onClick={() => {
              params.set("action", "generate-invoice")
              setParams(params)
            }}
          >
            Generate Invoice For Trader
          </MenuItem>
        </Menu>
      }
    >
      <Button
        className="!bg-gray-300"
        intent="none"
        leftIcon={<CaretDown weight="fill" />}
      >
        Action
      </Button>
    </Popover>
  )
}

export default TradeActions
