import {
  Drawer,
  NoDataFound,
  Timeline,
  TimeLineItem,
} from "@vesatogo/grass-core"
import { inr } from "@vesatogo/utils"
import dayjs from "dayjs"
import { Faders, Receipt } from "phosphor-react"
import { useSearchParams } from "react-router-dom"
import { useTimelineQuery } from "../generated/graphql"

const RequestTimeline = () => {
  const [params, setParams] = useSearchParams()
  const tradeId = parseInt(params.get("timeline") || "")
  const [{ data }] = useTimelineQuery({
    pause: tradeId ? false : true,
    variables: {
      tradeId,
    },
  })

  const trade = data?.trade_by_pk
  if (!trade) return null
  const seller = trade?.seller?.user[0]
  const buyer = trade?.buyer?.user[0]
  return (
    <Drawer
      isOpen={tradeId ? true : false}
      title={
        <span className="flex items-center text-lg text-gray-700">
          <Faders className="mr-1" size={20} />
          Timeline
        </span>
      }
      onClose={() => {
        params.delete("timeline")
        setParams(params)
      }}
      contentClassName="flex flex-col"
    >
      <div className="bg-white p-4 rounded mb-4">
        <h6 className="font-600 mb-4">Summary</h6>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between w-full">
            <div>Entry ID</div>
            <div>{trade.tracking_code}</div>
          </div>
          <div className="flex justify-between w-full">
            <div>Seller</div>
            <div>
              <div>
                {seller?.full_name} |{" "}
                <span className="text-gray-600">
                  {seller?.current_address?.village || "na"}
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div>Buyer</div>
            <div>
              <div>
                {buyer?.full_name} |{" "}
                <span className="text-gray-600">
                  {buyer?.current_address?.village || "na"}
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div>Commodity</div>
            <div>
              {trade.commodity.name}{" "}
              {trade.commodity_variety?.id
                ? `( ${trade.commodity_variety?.name} )`
                : null}
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div>Total Amount</div>
            <div className="font-700">{inr(trade.total_amount)}</div>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded relative overflow-y-auto">
        {trade?.action_logs?.length ? (
          <Timeline>
            {trade?.action_logs.map(log => (
              <TimeLineItem
                key={log.id}
                title={
                  <span className="flex justify-between w-full">
                    <span className="text-base font-700">
                      {log.change_title}
                    </span>
                    <span className="text-gray-600 text-sm">
                      {dayjs(log.created_at).format("D MMM, YYYY hh:mm A")}
                    </span>
                  </span>
                }
                bullet={<Receipt size={12} />}
              >
                <div
                  className="text-gray-500"
                  dangerouslySetInnerHTML={{ __html: log.change_message }}
                />
              </TimeLineItem>
            ))}
          </Timeline>
        ) : (
          <NoDataFound
            absolute={false}
            title="Unable to find action logs"
            subtitle="Whenever a pavti is approved or rejected by the seller or buyer it will update over here"
          />
        )}
      </div>
    </Drawer>
  )
}

export default RequestTimeline
