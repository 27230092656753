import { Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { Activity, Article, ShoppingBag } from "phosphor-react"
import { Navigate, NavLink, Outlet } from "react-router-dom"
import TradeInvoices from "~/pages/d/trade-requests/invoices"
import RequestTimeline from "../components/RequestTimeline"
import TradeMetrics from "../pages/d/trade-metrics"
import AllTradeRequests from "../pages/d/trade-requests"
import TradeReceipts from "../pages/d/trade-requests/receipts"
import ReceiptDetail from "../pages/d/trade-requests/receipts/[id]"
import TradeRequestDetail from "../pages/d/trade-requests/[id]"
import { TradeTemplates } from "../pages/d/trade-templates"

function SidebarMenuItem({ link, name }: { link: string; name: string }) {
  return (
    <MenuItem className="p-0 [&>div]:m-0">
      <NavLink
        className={({ isActive }) => `p-2 block ${isActive && "bg-gray-200"}`}
        to={link}
      >
        {name}
      </NavLink>
    </MenuItem>
  )
}

export const dashboardRoutes: any[] = [
  {
    path: "trade-metrics",
    title: "Trade Metrics",
    icon: <Activity weight="fill" />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <TradeMetrics />,
      },
    ],
  },
  {
    path: "trade-requests",
    title: "Trade Requests",
    icon: <ShoppingBag weight="fill" />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <AllTradeRequests />,
      },
      {
        path: ":id",
        element: <TradeRequestDetail />,
      },
      {
        path: "receipts",
        element: <TradeReceipts />,
      },
      {
        path: "invoices",
        element: <TradeInvoices />,
      },
      {
        path: "receipts/:id",
        element: <ReceiptDetail />,
      },
    ],
  },
  {
    path: "trade-templates",
    title: "Trade Templates",
    icon: (
      <Popover
        minimal
        arrow={false}
        className="p-0 border-t border-gray-200 ml-4 -mt-4"
        trigger="mouseover"
        interactive
        animation="fade"
        placement="right-start"
        content={
          <Menu className="max-h-52">
            <SidebarMenuItem link="trade-templates/expenses" name="Expenses" />
            <SidebarMenuItem
              link="trade-templates/seller"
              name="Seller Terms"
            />
            <SidebarMenuItem link="trade-templates/buyer" name="Buyer Terms" />
          </Menu>
        }
      >
        <Article weight="fill" />
      </Popover>
    ),
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="expenses" />,
      },
      {
        path: "expenses",
        title: "Expenses Templates",
        element: <TradeTemplates type="expenses" />,
      },
      {
        path: "seller",
        title: "Seller Terms Templates",
        element: <TradeTemplates type="seller" />,
      },
      {
        path: "buyer",
        title: "Buyer Terms Templates",
        element: <TradeTemplates type="buyer" />,
      },
    ],
  },
]
