import { useTranslation } from "react-i18next"
import { Avatar, Group, Image, Text } from "@vesatogo/grass-core"
import { DetailCard } from "../../components/DetailCard"
import plantIcon from "../../assets/plant.svg"
import locationIcon from "../../assets/location.svg"
import { IUserTypeProps } from "../../lib/types"
import dayjs from "dayjs"

export function UserCopy({ data, userType }: IUserTypeProps) {
  const { t } = useTranslation()
  const address = data?.plot_crop?.plot_crop[0].plot.field.address?.raw_address
  return (
    <DetailCard
      header={
        <Text className="font-normal !text-gray-800">
          {t(userType === "s" ? "pavti.header.1" : "pavti.header.2")}
        </Text>
      }
    >
      <Group className="p-2">
        <Text size="sm" className="!text-gray-800">
          {t("date.label")}: {dayjs(data?.created_at).format("DD MMM YY")}
        </Text>
        {address ? (
          <Group className="flex gap-2 mt-2">
            <Image src={locationIcon as unknown as string} />
            <Text size="sm" className="!text-gray-800">
              {address}
            </Text>
          </Group>
        ) : null}
        <Group className="flex items-center mt-3">
          <Avatar size="lg" />
          <Group className="self-start ml-2">
            <Text className="text-xl font-medium !text-gray-800">
              {data?.seller?.name}
            </Text>
            <Text size="sm">
              <span>{t("seller.label.1")}</span> |{" "}
              <span className="text-gray-400">{t("seller.label.2")}</span>
            </Text>
          </Group>
        </Group>
        <Group className="flex items-center mt-3">
          <Avatar size="lg" />
          <Group className="self-start ml-2">
            <Text className="text-xl font-medium !text-gray-800">
              {data?.buyer?.name}
            </Text>
            <Text size="sm">
              <span>{t("buyer.label.1")}</span> |{" "}
              <span className="text-gray-400">{t("buyer.label.2")}</span>
            </Text>
          </Group>
        </Group>
        <Group className="flex my-3 items-center">
          <Image src={plantIcon as unknown as string} />
          <Text size="sm" className="font-medium !text-gray-800 ml-2">
            {t("packaging") + " :"}
          </Text>
          <Text className="ml-auto !text-gray-800">
            {t("enum.packaging." + data?.packaging)}
          </Text>
        </Group>
      </Group>
    </DetailCard>
  )
}
