export const AppRoutes = {
  home: "/",
  tradeReceipts: "/d/trade-requests/receipts",
  tradeInvoices: "/d/trade-requests/invoices",
  tradeReceiptDetail(id: string | number) {
    return `/d/trade-requests/receipts/${id}`
  },
  tradeRequests: "/d/trade-requests",
} as const

export const APIRoutes = {
  downloadTradeReceipt(id: string | number) {
    return `rest/trade/receipt_pdf/${id}/`
  },
  downloadPavti(hash: string) {
    return `rest/trade/receipt/${hash}`
  },
  downloadInvoice(id: string | number) {
    return `rest/trade/invoice_pdf/${id}/`
  },
}
