import { useTranslation } from "react-i18next"
import {
  Button,
  Group,
  Image,
  Input,
  Modal,
  Text,
  Toast,
} from "@vesatogo/grass-core"
import warningIcon from "../../assets/warning.svg"
import checkIcon from "../../assets/check.svg"
import { IStickyFooterProps } from "../../lib/types"
import { useUpdateTradeVerificationMutation } from "../../../../generated/graphql"
import { useState } from "react"
import { useWindowScroll } from "@mantine/hooks"
import { getLocalStorage, getUserType } from "../../lib/utils"
import { downloadPdf } from "../../../../utils/downloadPdf"
import { toast } from "react-hot-toast"
import { useParams } from "react-router-dom"
import { userTypeMapper } from "../../lib/data"
import { APIRoutes } from "~/constants/routes"

export function StickyFooter({
  id,
  userType,
  page,
  data,
  setPage,
}: IStickyFooterProps) {
  const { t } = useTranslation()
  const { id: hash } = useParams()
  const [rejectModal, setRejectModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false)
  const [remarks, setRemarks] = useState("")
  const [, UpdateTradeVerification] = useUpdateTradeVerificationMutation()
  const [, scrollTo] = useWindowScroll()

  const submitted = data?.[`${getUserType(userType).name}_verification`]
  const bothVerified = data?.buyer_verification && data?.seller_verification

  function handleUpdate(value: boolean) {
    UpdateTradeVerification({
      id,
      set: {
        [`${getUserType(userType).name}_verification`]: value,
        [`${getUserType(userType).name}_remark`]: remarks,
      },
    }).then(() => {
      scrollTo({ y: 0 })
      setRejectModal(false)
      setApproveModal(false)
      setPage && setPage(1)
    })
  }

  function handleOpenPdf() {
    let token = getLocalStorage(`${hash}`)?.token
    downloadPdf(
      APIRoutes.downloadPavti(hash?.toString() as string),
      token
    ).catch(err => {
      return toast.custom(
        <Toast title={"Unable to download the receipt"} intent="danger" />
      )
    })
  }

  return (
    <>
      <Group className="flex justify-between items-center">
        {setPage && page === 1 ? (
          <>
            <Text>{t(getUserType(userType).name + ".next.pavati")}</Text>
            <Button
              variant="solid"
              className="!bg-green-400"
              onClick={() => setPage(2)}
            >
              {t("next")}
            </Button>
          </>
        ) : bothVerified ? (
          <Button fullWidth onClick={() => handleOpenPdf()}>
            Download PDF
          </Button>
        ) : submitted !== null ? (
          <Text className="text-green-300 text-center w-full py-2">
            Waiting for {getUserType(userType, true).title}'s approval
          </Text>
        ) : (
          <>
            <Button
              variant="outline"
              color="red"
              className="border !border-red-500 !text-red-500 gap-2"
              leftIcon={<Image src={warningIcon as unknown as string} />}
              onClick={() => setRejectModal(true)}
            >
              {t("reject")}
            </Button>
            <Button
              variant="solid"
              className="!bg-green-400 gap-2"
              leftIcon={<Image src={checkIcon as unknown as string} />}
              onClick={() => setApproveModal(true)}
            >
              {t("confirm")}
            </Button>
          </>
        )}
      </Group>
      <Modal
        isOpen={rejectModal}
        onClose={() => setRejectModal(false)}
        title={t("alert")}
      >
        <Text>
          {t("remark")} <span className="text-red">*</span>
        </Text>
        <Input
          value={remarks}
          onChange={e => setRemarks(e.target.value)}
          className="w-full"
          required
        />
        <Group className="flex justify-end gap-4 mt-4">
          <Button variant="outline" onClick={() => setRejectModal(false)}>
            {t("cancel")}
          </Button>
          <Button onClick={() => handleUpdate(false)}>{t("ok")}</Button>
        </Group>
      </Modal>
      <Modal
        isOpen={approveModal}
        onClose={() => setApproveModal(false)}
        title={t("alert")}
      >
        <Text>{t("approve.confirm")}</Text>
        <Group className="flex justify-end gap-4 mt-4">
          <Button variant="outline" onClick={() => setApproveModal(false)}>
            {t("cancel")}
          </Button>
          <Button onClick={() => handleUpdate(true)}>{t("ok")}</Button>
        </Group>
      </Modal>
    </>
  )
}
