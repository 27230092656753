import { useTranslation } from "react-i18next"
import { Card, Text } from "@vesatogo/grass-core"
import { DetailCard } from "../../components/DetailCard"
import { IUserTypeProps } from "../../lib/types"

export function TradeTC({ data, userType }: IUserTypeProps) {
  const { t } = useTranslation()
  const tnc = userType === "b" ? data?.buyer_tnc || "" : data?.seller_tnc || ""
  return tnc ? (
    <Card className="overflow-hidden mt-4 !rounded-lg">
      <DetailCard
        header={
          <Text className="font-normal !text-gray-800">{t("trade.tcs")}</Text>
        }
      >
        <div className="p-2" dangerouslySetInnerHTML={{ __html: tnc }}></div>
      </DetailCard>
    </Card>
  ) : null
}
