import { Editor } from "@tinymce/tinymce-react"
import { FormGroup, QuerySelector } from "@vesatogo/grass-core"
import { useState } from "react"
import { useClient } from "urql"
import { useTncConfigVersionsQuery } from "../generated/graphql"
import { TNC_CONFIG_DETAIL } from "../graphql/trade_template.gql"
import { FormCard } from "../pages/d/trade-requests/[id]"

type TermsAndConditionsProps = {
  isDisabled?: boolean
  seller_tnc?: any
  buyer_tnc?: any
  kind?: any
  mode?: any
  sharedDeptId?: any
  onChange: (path: "seller_tnc" | "buyer_tnc", value: any) => void
}
const TermsAndConditions = ({
  isDisabled,
  seller_tnc,
  buyer_tnc,
  kind,
  mode,
  sharedDeptId,
  onChange,
}: TermsAndConditionsProps) => {
  const commonVariables = {
    department: `${sharedDeptId}`,
    kind,
    mode,
  }

  const client = useClient()
  const [state, setState] = useState({
    buyer: null,
    seller: null,
  })
  function handleTemplateChange(path: "buyer" | "seller") {
    return {
      value: state[path],
      variables: {
        ...commonVariables,
        role: path,
      },
      onChange: async value => {
        try {
          const { data } = await client
            .query(TNC_CONFIG_DETAIL, {
              ...commonVariables,
              version: value?.id,
              role: path,
            })
            .toPromise()

          const tnc = data?.trade_tnc_template_detail?.[path]
          onChange((path + "_tnc") as any, tnc)
          setState(prev => ({
            ...prev,
            [path]: value,
          }))
        } catch {}
      },
    }
  }
  return (
    <FormCard title="Terms and Conditions" childrenClassName="flex gap-4">
      <FormGroup
        labelProps={{ className: "text-base" }}
        className={"w-full"}
        label={
          <div className="flex justify-between items-center px-3">
            <span>Farmer Terms</span>
            {!isDisabled && (
              <QuerySelector
                dataHook={useTncConfigVersionsQuery}
                className="ml-auto font-400"
                {...handleTemplateChange("seller")}
              />
            )}
          </div>
        }
      >
        <Editor
          apiKey={import.meta.env.VITE_EDITOR_KEY}
          value={seller_tnc}
          onEditorChange={value => onChange("seller_tnc", value)}
        />
      </FormGroup>
      <FormGroup
        labelProps={{ className: "text-base" }}
        className={"w-full"}
        label={
          <div className="flex justify-between items-center px-3">
            <span>Trader Terms</span>
            {!isDisabled && (
              <QuerySelector
                dataHook={useTncConfigVersionsQuery}
                className="ml-auto font-400"
                {...handleTemplateChange("buyer")}
              />
            )}
          </div>
        }
      >
        <Editor
          apiKey={import.meta.env.VITE_EDITOR_KEY}
          value={buyer_tnc}
          onEditorChange={value => onChange("buyer_tnc", value)}
        />
      </FormGroup>
    </FormCard>
  )
}

export default TermsAndConditions
