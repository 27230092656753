import i18n from "i18next"
import {
  Group,
  Image,
  Menu,
  MenuItem,
  Popover,
  Text,
} from "@vesatogo/grass-core"
import LanguageIcon from "../assets/language.svg"
import { useLocalStorage } from "@mantine/hooks"
import { config } from "../config"
import { IHeaderProps } from "../lib/types"
import { CaretLeft } from "phosphor-react"

export function Header({ title, page, setPage }: IHeaderProps) {
  const [lang, setLocal] = useLocalStorage({
    key: "lang",
    defaultValue: config.defaultLang,
  })
  function handleLanguage(lang: "en" | "hi" | "mr") {
    setLocal(lang)
    i18n.changeLanguage(lang)
  }
  return (
    <Group className="flex justify-between border-b border-gray-300 p-4 bg-white shadow-sm fixed top-0 left-0 w-full z-10">
      <Group className="flex items-center gap-4">
        {setPage && page === 2 ? (
          <CaretLeft size={24} onClick={e => setPage(1)} />
        ) : null}
        <Text className="text-xl font-medium !text-gray-700 ">{title}</Text>
      </Group>
      <Popover
        minimal
        arrow={false}
        className="p-0 border-t border-gray-200"
        trigger="click"
        interactive
        animation="fade"
        placement="bottom"
        content={
          <Menu className="max-h-52">
            <MenuItem
              selected={lang === "en"}
              onClick={() => handleLanguage("en")}
            >
              English
            </MenuItem>
            <MenuItem
              selected={lang === "hi"}
              onClick={() => handleLanguage("hi")}
            >
              हिन्दी
            </MenuItem>
            <MenuItem
              selected={lang === "mr"}
              onClick={() => handleLanguage("mr")}
            >
              मराठी
            </MenuItem>
          </Menu>
        }
      >
        <button>
          <Image width={26} src={LanguageIcon as unknown as string} />
        </button>
      </Popover>
    </Group>
  )
}
