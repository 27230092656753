import { Toast } from "@vesatogo/grass-core"

const PavtiNote = ({ kind }: { kind: string }) => {
  return (
    <>
      {kind === "pro_forma" ? (
        <Toast
          title={"Pro Forma Sauda Pauti"}
          subtitle="This is temporary and is subject to change later."
          className="max-w-full"
          intent="warning"
          variant="minimal"
        />
      ) : (
        <Toast
          title={"Permanent Sauda Pauti"}
          subtitle="No information can be changed later. The invoice will be created directly now."
          className="max-w-full"
          variant="minimal"
        />
      )}
    </>
  )
}

export default PavtiNote
