import { Selector, Tag, Group } from "@vesatogo/grass-core"
import { DateInput } from "@vesatogo/grass-dates"
import { useState } from "react"
import { useTradeMetricsQuery } from "../../../generated/graphql"
import { useDepartment } from "../../../utils/useDepartment"
import { MetricsCard, MetricsSection } from "./MetricsCard"

function TradeMetrics() {
  const [tradeMode, setTradeMode] = useState<{ id: string; name: string }>({
    id: "facilitator",
    name: "Facilitator",
  })
  const [date, setDate] = useState<{
    startDate?: Date | null
    endDate?: Date | null
  }>({})
  const { id: sharedDeptId } = useDepartment()

  function getDurationDate(duration: string) {
    let date = new Date()
    if (duration === "24H") date.setDate(date.getDate() - 1)
    if (duration === "1W") date.setDate(date.getDate() - 7)
    if (duration === "1M") date.setMonth(date.getMonth() - 1)
    if (duration === "3M") date.setMonth(date.getMonth() - 3)
    if (duration === "6M") date.setMonth(date.getMonth() - 6)
    if (duration === "1Y") date.setFullYear(date.getFullYear() - 1)
    return { startDate: date, endDate: new Date() }
  }

  const commonVariables = {
    date: {
      ...(date.startDate && { _gt: date.startDate }),
      ...(date.endDate && { _lte: date.endDate }),
    },
    mode: tradeMode?.id,
    department: sharedDeptId,
    parent: { _is_null: true },
  }

  const [{ data: saudaPavti }] = useTradeMetricsQuery({
    variables: {
      ...commonVariables,
      kind: "pro_forma",
    },
    pause: !sharedDeptId,
    requestPolicy: "network-only",
  })

  const [{ data: hishobPatti }] = useTradeMetricsQuery({
    variables: {
      ...commonVariables,
      kind: "final",
    },
    pause: !sharedDeptId,
    requestPolicy: "network-only",
  })

  const [{ data: bookingPatti }] = useTradeMetricsQuery({
    variables: {
      ...commonVariables,
      kind: "booking",
    },
    pause: !sharedDeptId,
    requestPolicy: "network-only",
  })

  return (
    <div>
      <Group className="flex items-center flex-wrap gap-6 justify-end p-2 border-b-1 border-b-gray-300">
        <Selector
          options={[
            { id: "direct", name: "Direct" },
            { id: "facilitator", name: "Facilitator" },
          ]}
          isClearable={false}
          value={tradeMode}
          onChange={(value: any) => setTradeMode(value)}
        />
        <Group className="flex gap-2">
          <Tag size="lg" onClick={() => setDate(getDurationDate("24H"))}>
            24H
          </Tag>
          <Tag size="lg" onClick={() => setDate(getDurationDate("1W"))}>
            1W
          </Tag>
          <Tag size="lg" onClick={() => setDate(getDurationDate("1M"))}>
            1M
          </Tag>
          <Tag size="lg" onClick={() => setDate(getDurationDate("3M"))}>
            3M
          </Tag>
          <Tag size="lg" onClick={() => setDate(getDurationDate("6M"))}>
            6M
          </Tag>
          <Tag size="lg" onClick={() => setDate(getDurationDate("1Y"))}>
            1Y
          </Tag>
        </Group>
        <Group className="flex gap-2">
          <DateInput
            className="w-40"
            value={date.startDate}
            onChange={value => setDate(prev => ({ ...prev, startDate: value }))}
          />
          <DateInput
            className="w-40"
            value={date.endDate}
            onChange={value => setDate(prev => ({ ...prev, endDate: value }))}
            popoverPanelClassName="right-0"
          />
        </Group>
      </Group>
      <MetricsSection title="Sauda Pavti Metrics">
        <MetricsCard
          title="Trades"
          subtitle="Total number of trades"
          count={saudaPavti?.trade_aggregate.aggregate?.count}
        />
        <MetricsCard
          title="Value (INR)"
          subtitle="Total trade value"
          count={saudaPavti?.trade_aggregate.aggregate?.sum?.total_amount}
        />
        <MetricsCard
          title="Quantity (MT)"
          subtitle="Total trade quantity"
          count={saudaPavti?.trade_aggregate.aggregate?.sum?.total_quantity}
        />
      </MetricsSection>
      <MetricsSection title="Hishob Patti Metrics">
        <MetricsCard
          title="Trades"
          subtitle="Total number of trades"
          count={hishobPatti?.trade_aggregate.aggregate?.count}
        />
        <MetricsCard
          title="Value (INR)"
          subtitle="Total trade value"
          count={hishobPatti?.trade_aggregate.aggregate?.sum?.total_amount}
        />
        <MetricsCard
          title="Quantity (MT)"
          subtitle="Total trade quantity"
          count={hishobPatti?.trade_aggregate.aggregate?.sum?.total_quantity}
        />
      </MetricsSection>
      <MetricsSection title="Booking Patti Metrics">
        <MetricsCard
          title="Trades"
          subtitle="Total number of trades"
          count={bookingPatti?.trade_aggregate.aggregate?.count}
        />
        <MetricsCard
          title="Value (INR)"
          subtitle="Total trade value"
          count={bookingPatti?.trade_aggregate.aggregate?.sum?.total_amount}
        />
        <MetricsCard
          title="Quantity (MT)"
          subtitle="Total trade quantity"
          count={bookingPatti?.trade_aggregate.aggregate?.sum?.total_quantity}
        />
      </MetricsSection>
    </div>
  )
}

export default TradeMetrics
