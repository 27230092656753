import { useTranslation } from "react-i18next"
import { Checkbox, Group, Text } from "@vesatogo/grass-core"
import { IAgreeTermsProps } from "../lib/types"
import { useLocalStorage } from "@mantine/hooks"

export function AgreeTerms({ value, onChange }: IAgreeTermsProps) {
  const { t } = useTranslation()
  const [lang] = useLocalStorage({
    key: "lang",
    defaultValue: "en",
  })
  return (
    <Group className="mt-6 mb-12">
      <Checkbox
        type="checkbox"
        size="lg"
        label={
          <a
            href={`${
              import.meta.env.VITE_PLATFORM_TC_URL
            }/${lang}/terms-of-use`}
            target="_blank"
            className="underline text-gray-800"
          >
            {t("trade.tcs.approval")}
          </a>
        }
        checked={value}
        onChange={e => onChange(prev => !prev)}
      />
    </Group>
  )
}
