import "@vesatogo/grass-carousel/index.css"
import { Toast } from "@vesatogo/grass-core"
import { GrassLogin } from "@vesatogo/grass-login"
import "@vesatogo/grass-login/index.css"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import {
  useAgentLoginMutation,
  useAuthenticatedUserQuery,
} from "../generated/graphql"
import { useClient } from "../services/urql.service"
import { ReactComponent as Logo } from "../assets/logo.svg"
import Farmer from "@vesatogo/grass-assets/graphics/farmer.png"
import Accounts from "@vesatogo/grass-assets/graphics/accounts.png"
import Trading from "@vesatogo/grass-assets/graphics/trading.png"
import { useForm } from "@mantine/form"
const LoginPage = () => {
  const navigate = useNavigate()
  const { resetClient } = useClient()
  const { getInputProps, values } = useForm({
    initialValues: {
      username: "",
      password: "",
    },
  })
  const [{ data }] = useAuthenticatedUserQuery()

  useEffect(() => {
    if (data?.me?.id) {
      navigate("/d")
    }
  }, [data])
  const [, login] = useAgentLoginMutation()
  const slides = [
    {
      id: 1,
      title: "Farmer",
      subtitle: "Manager the supplier directory",
      renderImage: style => {
        return (
          <img
            className={style}
            src={Farmer}
            alt="Farmer"
            width={500}
            height={500}
          />
        )
      },
    },
    {
      id: 2,
      title: "Trading",
      subtitle:
        "Enable easy procurement process and keep comfortable records of transactions.",
      renderImage: style => {
        return (
          <img
            className={style}
            src={Trading}
            alt="Farmer"
            width={500}
            height={500}
          />
        )
      },
    },
    {
      id: 3,
      title: "Accounts",
      subtitle: "Check expenses in processes and take actions accordingly.",
      renderImage: style => {
        return (
          <img
            className={style}
            src={Accounts}
            alt="Farmer"
            width={500}
            height={500}
          />
        )
      },
    },
  ]
  return (
    <GrassLogin
      slides={slides}
      brand={{
        logo: Logo,
        displayName: "SetuTrade",
        legalName: "FarmSetu Technologies Pvt. Ltd.",
        phoneNumber: "8799932484",
        email: "support@farmsetu.co",
        website: "https://farmsetu.co/",
      }}
      usernameProps={{
        inputProps: {
          ...getInputProps("username"),
          placeholder: "Employee ID",
        },
      }}
      passwordProps={{
        inputProps: getInputProps("password"),
      }}
      onSubmit={async () => {
        try {
          const { data, error } = await login(values)
          if (error || !data?.agent_login) {
            return toast.custom(
              <Toast
                title={"Unable to login, please enter valid credentials"}
                intent="danger"
              />
            )
          }
          const auth = data?.agent_login
          const agent = auth?.me?.agent
          const org = agent?.organisation
          toast.custom(<Toast title={`Logged in as ${agent?.eid}`} />)
          if (org?.codename) {
            localStorage.setItem("token", auth.token)
            resetClient()
            navigate("/d")
          }
        } catch (error) {
          console.error(error)
        }
      }}
    />
  )
}

export default LoginPage
