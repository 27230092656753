import { useTranslation } from "react-i18next"
import { Group, Text } from "@vesatogo/grass-core"
import { DetailCard } from "../../components/DetailCard"
import { IUserTypeProps } from "../../lib/types"
import { getUserType } from "../../lib/utils"
import { inr } from "@vesatogo/utils"

export function Expenses({ data, userType }: IUserTypeProps) {
  const { t } = useTranslation()
  const userTypeMap = getUserType(userType)
  return (
    <DetailCard
      header={
        <Text className="font-normal !text-gray-800">
          {t(userTypeMap.name + ".expense")}
        </Text>
      }
    >
      {data?.[`${userTypeMap.name}_expenses`].nodes.map((item, i) => (
        <Group
          key={i}
          className="flex justify-between items-start p-2 border-t border-gray-300"
        >
          <div>
            <Text size="sm" className="!text-gray-800">
              {item.expense_parameter.name}
            </Text>
            <Text size="sm" className="!text-gray-600">
              {item.kind !== "flat" && data.kind === "final"
                ? item.value
                : t("enum.expense_kind." + item.kind)}
            </Text>
          </div>
          <Group className="flex items-center gap-1">
            <Text size="sm">{userType === "s" ? "-" : ""}</Text>
            <Text size="sm" className="!text-gray-800">
              {inr(data.kind === "final" ? item.amount : item.value)}
            </Text>
          </Group>
        </Group>
      ))}

      <Group className="flex justify-between items-start p-2 border-t border-gray-300">
        <Text size="sm" className="flex-1">
          {t("total.expense")}
        </Text>
        <Group className="flex items-center gap-1">
          <Text size="sm">{userType === "s" ? "-" : ""}</Text>
          <Text size="sm" className="!text-gray-800">
            {inr(data?.[`${userTypeMap.name}_expenses`].aggregate?.sum?.amount)}
          </Text>
        </Group>
      </Group>
    </DetailCard>
  )
}
