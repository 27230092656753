import { groupBy, isEmpty } from "lodash-es"
import { EXPENSE } from "~/pages/d/trade-requests/receipts/[id]"
import { EXPENSE_KINDS } from "../constants/static.items"

export function expenseAmount({
  kind,
  buyerAmount,
  sellerAmount,
  totalQuantity,
  finalQuantity,
  finalAmount,
  trades = 1,
}): number {
  if (kind === "amount_percent")
    return ((buyerAmount + sellerAmount) / 100) * finalAmount
  if (kind === "quantity_per_unit")
    return (buyerAmount + sellerAmount) * totalQuantity
  if (kind === "deducted_quantity_per_unit")
    return (buyerAmount + sellerAmount) * finalQuantity
  return (buyerAmount + sellerAmount) * trades
}

export function normalizeExpenses(expenses: any[]) {
  const expenseGroup = groupBy(expenses, "expense_parameter.id")
  return isEmpty(expenseGroup)
    ? [EXPENSE]
    : Object.keys(expenseGroup).map(key => {
        const expenses = expenseGroup[key]
        const farmer = expenses.find(e => e.paid_by === "seller")
        const trader = expenses.find(e => e.paid_by === "buyer")
        const kind = EXPENSE_KINDS.find(
          e => e.id === farmer?.kind || e.id === trader?.kind
        )
        return {
          expense_parameter:
            farmer?.expense_parameter || trader?.expense_parameter,
          seller: farmer,
          buyer: trader,
          kind: kind,
          amount: (farmer?.amount || 0) + (trader?.amount || 0),
        }
      })
}
