import { useLocalStorage } from "@mantine/hooks"
import { Button, Group, Input, Text } from "@vesatogo/grass-core"
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { CardWithFooter } from "../components/CardWithFooter"
import { Note } from "../components/Note"
import { Countdown } from "../components/Countdown"
import { config } from "../config"
import { getHashValue } from "../lib/utils"
import { IOTPProps } from "../lib/types"
import {
  useGenerateTradeOtpMutation,
  useVerifyTradeOtpMutation,
  VerifyTradeOtp,
} from "../../../generated/graphql"
import { useClient } from "../../../services/urql.service"
import { useTranslation } from "react-i18next"
import { Warning } from "phosphor-react"

export function OTPCard({ token, setOtpSent }: IOTPProps) {
  const { t } = useTranslation()
  const [OTP, setOTP] = useState<(number | "")[]>(
    new Array(config.otpCount).fill("")
  )
  const [currentInputIndex, setCurrentInputIndex] = useState(0)
  const currentInput = useRef<HTMLInputElement>(null)
  const [countDown, setCountDown] = useState(config.resendDuration)
  const hashValue = getHashValue(token)

  const [, VerifyTradeOTP] = useVerifyTradeOtpMutation()
  const [, GenerateTradeOTP] = useGenerateTradeOtpMutation()
  const [, setVerifiedOTP] = useLocalStorage<VerifyTradeOtp>({
    key: `${token}`,
  })
  const { resetClient } = useClient()

  function getOTPNumberArray<T extends typeof OTP>(
    array: T,
    value: T[0],
    index: number
  ) {
    let beforeIndex = array.slice(0, index)
    let afterIndex = array.slice(index + 1)
    return [...beforeIndex, value, ...afterIndex]
  }

  function handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
    let data = e.clipboardData.getData("Text")
    if (!/[0-9]/.test(String(data)) || data.length !== config.otpCount) return
    let numberArray = data.split("").map(item => Number(item))
    setOTP(() => numberArray)
    setCurrentInputIndex(numberArray.length - 1)
  }

  function handleKeyDown(key: string, index: number) {
    /*if (/[0-9]/.test(key)) {
      setCurrentInputIndex(index + 1)
      setOTP(prev => getOTPNumberArray(prev, Number(key), index))
    }*/
    if (key === "Backspace") {
      if (!String(OTP[index])) setCurrentInputIndex(index - 1)
      else setOTP(prev => getOTPNumberArray(prev, "", index))
    }
  }

  function handleOTPChange(value: string | number, index: number) {
    // Check: do not proceed if the value is empty string or is not a number
    // if (value && !/[0-9]/.test(String(value))) return
    if (!/[0-9]/.test(String(value))) return
    setCurrentInputIndex(value ? index + 1 : index - 1)
    setOTP(prev => {
      let numVal = value ? Number(String(value).slice(-1)) : (value as number)
      return getOTPNumberArray(prev, numVal, index)
    })
  }

  function resetOTP() {
    setOTP(new Array(config.otpCount).fill(""))
    setCurrentInputIndex(0)
  }

  function resendOTP(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault()
    setCountDown(config.resendDuration)
    GenerateTradeOTP({ token }).then(({ data }) => {
      setOtpSent(data?.generate_trade_otp?.status || false)
      resetOTP()
    })
  }

  function verifyOTP() {
    VerifyTradeOTP({ token, otp: OTP.join("") }).then(({ data }) => {
      if (data?.verify_trade_otp) {
        setVerifiedOTP(data.verify_trade_otp)
        setOtpSent(false)
        resetClient()
      } else {
        resetOTP()
        alert("OTP Incorrect")
      }
    })
  }

  useEffect(() => {
    currentInput.current?.focus()
  }, [currentInputIndex])

  return (
    <>
      <Note text={t("sensitive.information")} icon={<Warning size={20} />} />
      <form
        onSubmit={e => {
          e.preventDefault()
          verifyOTP()
        }}
      >
        <CardWithFooter
          footerSection={
            <Group className="flex justify-between">
              <Button
                type="button"
                variant="outline"
                className="!border-gray-600 !text-gray-600"
                onClick={e => setOtpSent(false)}
              >
                {t("cancel")}
              </Button>
              <Button
                type="submit"
                variant="solid"
                className="!bg-gray-700"
                disabled={!OTP.every(num => String(num))}
              >
                {t("verify.number")}
              </Button>
            </Group>
          }
        >
          <Text className="mb-4 text-xl font-medium !text-gray-800">
            {t("enter.code")}
          </Text>
          <Text size="sm" className="mb-1 !text-gray-800">
            {t("otp.sent")}
          </Text>
          <Text
            size="sm"
            className="mb-8 underline !text-gray-800 underline-offset-4"
          >
            XXXXXX{hashValue?.phone}
          </Text>

          <Group className="flex flex-wrap w-full gap-2 justify-center mb-1">
            {OTP.map((num, index) => (
              <Input
                ref={currentInputIndex === index ? currentInput : null}
                key={index}
                className="w-9 !h-9 !hide-number-input-arrow !bg-gray-100 border-none shadow-sm shadow-gray-300"
                type="tel"
                value={num}
                onChange={e => handleOTPChange(e.target.value, index)}
                onKeyDown={e => handleKeyDown(e.key, index)}
                onPaste={e => handlePaste(e)}
              />
            ))}
          </Group>
          <Group className="flex justify-between w-full">
            {countDown == 0 ? (
              <>
                <span />
                <Text size="xs" className="underline !text-gray-600">
                  <Link to="#" onClick={resendOTP}>
                    {t("resend.otp")}
                  </Link>
                </Text>
              </>
            ) : (
              <>
                <Text size="xs" className="!text-gray-600 flex gap-1">
                  <Countdown
                    start={countDown}
                    handleEnd={() => setCountDown(0)}
                    text={t("time.left") + ": "}
                  />
                </Text>
                <Text size="xs" className="!text-gray-300">
                  {t("resend.otp")}
                </Text>
              </>
            )}
          </Group>
          <Text size="xs" className="!text-gray-500 mt-8 text-center px-5">
            {t("otp.screen.note")}
          </Text>
        </CardWithFooter>
      </form>
    </>
  )
}
