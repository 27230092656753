import { Checkbox, Modal, RadioGroup, Toast } from "@vesatogo/grass-core"
import { ArrowRight } from "phosphor-react"
import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import PavtiNote from "~/components/PavtiNote"
import {
  useBroadcastConsentMutation,
  useUpdateTradeMutation,
} from "../generated/graphql"

type SendConsentType = {
  isOpen: boolean
  onClose: () => void
  kind: string
  seller?: any
  buyer?: any
  tradeId: string
}
const SendConsent = ({
  isOpen,
  onClose,
  kind,
  buyer,
  seller,
  tradeId,
}: SendConsentType) => {
  const navigate = useNavigate()
  const [{ fetching }, broadcast] = useBroadcastConsentMutation()
  const [{ fetching: uFetching }, updateTrade] = useUpdateTradeMutation()
  const [consent, setConsent] = useState<any>()

  return (
    <Modal
      title="Alert"
      isOpen={isOpen}
      onClose={onClose}
      primaryActionButtonProps={{
        text: "Submit",
        async onClick() {
          if (!consent) {
            const { data } = await updateTrade({
              id: tradeId,
              _set: { current_status_id: "Draft" },
            })
            if (data?.update_trade_by_pk) {
              toast.custom(
                <Toast title={"Trade request status changed to 'Draft'"} />
              )
            }
          } else {
            const { data, error } = await broadcast({
              trade_id: tradeId,
              send_to_buyer: consent.buyer,
              send_to_seller: consent.seller,
            })
            if (error) {
              toast.custom(
                <Toast
                  intent="danger"
                  title={"Unable to send consents, please try again"}
                />
              )
            }
            if (data?.broadcast_consents) {
              toast.custom(
                <Toast
                  title={
                    "Trade request has been sent via sms to respective parties for approval"
                  }
                />
              )
              navigate(`/d/trade-requests?updatedAt=${Date.now()}`)
            }
          }
          onClose()
        },
        loading: fetching || uFetching,
      }}
      secondaryActionButtonProps={{
        text: "Cancel",
        onClick: onClose,
      }}
    >
      <PavtiNote kind={kind} />
      <RadioGroup
        value={consent ? "send" : "do-not-send"}
        onChange={e => {
          if (e === "send") {
            setConsent({ buyer: true, seller: true })
          } else {
            setConsent(null)
          }
        }}
        vertical
        className={"w-full mt-3"}
      >
        <Checkbox type={"radio"} value="do-not-send" label="Save to Draft" />
        <Checkbox type={"radio"} value="send" label="Send Consent">
          {consent ? (
            <div>
              <p>
                This action will send the SMS with verification links to{" "}
                <b>{seller?.name}</b> & <b>{buyer?.name}</b> <br />
                for complete verification of this trade
              </p>
              <div className="mt-3">
                <div className="flex gap-4">
                  <Checkbox
                    checked={consent.seller ? true : false}
                    onChange={value => {
                      setConsent(prev => ({ ...prev, seller: value }))
                    }}
                  >
                    <div>
                      <div className="font-700">Farmer (Seller)</div>
                      <div>
                        <div>{seller?.name}</div>
                        <div>{seller?.number}</div>
                      </div>
                    </div>
                  </Checkbox>
                  <div>
                    <ArrowRight />
                  </div>
                  <Checkbox
                    checked={consent.buyer ? true : false}
                    onChange={value => {
                      setConsent(prev => ({ ...prev, buyer: value }))
                    }}
                  >
                    <div>
                      <div className="font-700">Trader (Buyer)</div>
                      <div>
                        <div>{buyer?.name}</div>
                        <div>{buyer?.number}</div>
                      </div>
                    </div>
                  </Checkbox>
                </div>
              </div>
            </div>
          ) : null}
        </Checkbox>
      </RadioGroup>
    </Modal>
  )
}

export default SendConsent
