import { Editor } from "@tinymce/tinymce-react"
import {
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  FormGroup,
  Group,
  InputGroup,
  Note,
  RadioGroup,
  Selector,
  Tag,
  Text,
  Toast,
} from "@vesatogo/grass-core"
import { DateInput } from "@vesatogo/grass-dates"
import "@vesatogo/grass-dates/index.css"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import dayjs from "dayjs"
import { cloneDeep, get, isEmpty, set } from "lodash-es"
import { CheckCircle, Plus, Trash, X } from "phosphor-react"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { QuerySelector } from "../../../components/QuerySelector"
import SendConsent from "../../../components/SendConsent"
import UserDetailsCard from "../../../components/UserDetailsCard"
import Codenames from "../../../constants/Codenames"
import SharedEntityGroup from "../../../constants/SharedEntityGroup"
import { EXPENSE_KINDS, TRADE_KINDS } from "../../../constants/static.items"
import {
  BookingTradeSummaryQuery,
  Expense_Constraint,
  Expense_Update_Column,
  TradeDetailQuery,
  Trade_Item_Constraint,
  Trade_Item_Update_Column,
  useAddUpdateSharedEntityMutation,
  useAllCommoditiesQuery,
  useAllPlotCropsQuery,
  useAllSiUnitsQuery,
  useAllVarietiesQuery,
  useAllVarietyGradesQuery,
  useBookingTradeSummaryQuery,
  useDeleteExpenseItemMutation,
  useDeleteTradeItemMutation,
  useExpenseConfigsQuery,
  useExpenseConfigVersionsQuery,
  useExpenseParametersQuery,
  useGetSharedEntityQuery,
  useIncrementTradeCounterMutation,
  useTncConfigsQuery,
  useTncConfigVersionsQuery,
  useTradeDetailQuery,
  useUpsertTradeMutation,
  useUsersQuery,
} from "../../../generated/graphql"
import { getBookingQuantity } from "../../../utils/bookingPavtiUtils"
import { expenseAmount, normalizeExpenses } from "../../../utils/expenseAmount"
import { useDepartment } from "../../../utils/useDepartment"
import { SideBar } from "./sections/SideBar"

type FormCardProps = {
  children?: React.ReactNode
  title?: string
  className?: any
  action?: React.ReactNode
  childrenClassName?: any
}

export const FormCard = ({
  children,
  title,
  className,
  action,
  childrenClassName,
}: FormCardProps) => {
  return (
    <Card className={classNames("!rounded-md mb-4", className)}>
      <div className="p-4 text-green font-600 flex justify-between items-center">
        <div className="flex">
          <svg
            className="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4532 7.38013C11.9476 8.6269 14.3771 7.45863 14.3383 5.49004C14.3378 4.85175 14.084 4.23973 13.6327 3.78839C13.1814 3.33705 12.5693 3.08328 11.931 3.08281C9.65801 3.06992 8.65747 6.02203 10.4532 7.38013Z"
              fill="currentColor"
            />
            <path
              d="M8.02648 11.1874C8.1703 11.4163 8.36995 11.6049 8.60669 11.7356C8.84343 11.8662 9.10948 11.9345 9.37986 11.934H14.4822C14.7509 11.9315 15.0147 11.8617 15.2495 11.7311C15.4843 11.6004 15.6827 11.4131 15.8265 11.1861C15.9703 10.9592 16.0551 10.6998 16.0729 10.4317C16.0908 10.1636 16.0413 9.89523 15.9289 9.65117C15.5486 8.85343 14.9376 8.18823 14.175 7.7417C13.5784 8.33515 12.7725 8.67037 11.931 8.67506C11.089 8.66767 10.2828 8.33294 9.68318 7.7417C8.61865 8.40402 7.2182 9.85731 8.02648 11.1874Z"
              fill="currentColor"
            />
            <path
              d="M16.2281 6.57125C17.7243 7.81766 20.1662 6.66143 20.1249 4.68116C20.1249 4.27332 20.0213 3.87214 19.8238 3.5153C19.6263 3.15846 19.3414 2.85766 18.9958 2.64113C18.6501 2.42461 18.2552 2.29946 17.8479 2.27744C17.4407 2.25542 17.0345 2.33724 16.6675 2.51523C16.3006 2.69322 15.9849 2.96155 15.75 3.29501C15.5152 3.62847 15.369 4.01613 15.325 4.42161C15.2811 4.82708 15.3409 5.23707 15.4989 5.61309C15.6569 5.9891 15.9078 6.31881 16.2281 6.57125V6.57125Z"
              fill="currentColor"
            />
            <path
              d="M16.9827 9.14962C17.1624 9.52594 17.2542 9.9382 17.2511 10.3552C17.2498 10.608 17.2131 10.8593 17.1422 11.1019C17.1863 11.121 17.2341 11.1303 17.2822 11.1291H20.265C20.5354 11.1296 20.8015 11.0613 21.0382 10.9307C21.2749 10.8 21.4746 10.6114 21.6184 10.3824C22.4282 9.05248 21.0311 7.59562 19.9656 6.93677C18.705 8.22889 16.6818 8.0894 15.4311 6.95224C15.0779 7.10741 15.7256 7.35082 15.8782 7.59406C16.3352 8.04348 16.7091 8.57009 16.9827 9.14962Z"
              fill="currentColor"
            />
            <path
              d="M4.66249 6.57514C4.96446 6.81201 5.3187 6.97324 5.69565 7.04538C6.0726 7.11753 6.46134 7.09849 6.82944 6.98987C7.19754 6.88125 7.53433 6.68618 7.81173 6.42095C8.08912 6.15571 8.29907 5.82799 8.42407 5.46513C8.54907 5.10226 8.5855 4.71477 8.53031 4.33496C8.47512 3.95516 8.32992 3.59405 8.10681 3.28177C7.88371 2.96949 7.58916 2.71508 7.24776 2.53976C6.90635 2.36444 6.52797 2.2733 6.14417 2.27393C5.64729 2.28212 5.16497 2.44308 4.76276 2.73496C4.36056 3.02684 4.05796 3.43547 3.89611 3.90533C3.73425 4.37518 3.72098 4.88348 3.85809 5.36114C3.99521 5.83881 4.27606 6.26267 4.66249 6.57514Z"
              fill="currentColor"
            />
            <path
              d="M3.59673 11.1292H6.57959C6.62871 11.128 6.67731 11.1188 6.72346 11.1019C6.6491 10.86 6.6111 10.6083 6.61069 10.3552C6.59985 9.29511 7.2676 8.32665 7.98738 7.59405C8.14341 7.34778 8.78133 7.10784 8.43076 6.95242C7.18166 8.09817 5.13411 8.22144 3.89619 6.93286C2.8309 7.59663 1.43372 9.05127 2.24333 10.3825C2.38716 10.6114 2.58681 10.8001 2.82356 10.9307C3.0603 11.0613 3.32635 11.1296 3.59673 11.1292Z"
              fill="currentColor"
            />
            <path
              d="M22.4437 16.2259C22.364 16.0114 22.206 15.8349 22.0015 15.7323C21.7969 15.6296 21.561 15.6083 21.3414 15.6727L15.4964 17.4014C15.3593 17.8437 15.0733 18.2248 14.6869 18.4799C14.3005 18.735 13.8377 18.8483 13.3772 18.8007L10.1638 18.4793C10.1104 18.4743 10.0586 18.4587 10.0112 18.4336C9.96384 18.4084 9.92193 18.3742 9.88787 18.3327C9.85381 18.2913 9.82827 18.2436 9.81273 18.1923C9.7972 18.1409 9.79196 18.087 9.79732 18.0337C9.80268 17.9803 9.81855 17.9286 9.84399 17.8814C9.86944 17.8342 9.90396 17.7925 9.94559 17.7586C9.98721 17.7248 10.0351 17.6996 10.0865 17.6844C10.1379 17.6691 10.1919 17.6642 10.2452 17.6699L13.4585 17.9912C13.7405 18.0166 14.0223 17.941 14.2537 17.7778C14.4851 17.6147 14.651 17.3747 14.7218 17.1006C14.7927 16.8265 14.7638 16.5361 14.6405 16.2813C14.5171 16.0265 14.3072 15.8238 14.0483 15.7094L9.38287 13.7122C8.91268 13.5719 8.41886 13.5288 7.93148 13.5855C7.44409 13.6421 6.97332 13.7973 6.54783 14.0417L1.5 16.9581L3.49717 20.4195L11.7461 21.6723C12.7282 21.8228 13.7328 21.6561 14.6137 21.1965L22.0166 17.3405C22.2106 17.2378 22.3608 17.0683 22.4394 16.8634C22.5179 16.6584 22.5194 16.4319 22.4437 16.2259Z"
              fill="currentColor"
            />
          </svg>

          {title}
        </div>
        {action}
      </div>
      {children && (
        <>
          <hr />
          <div className={classNames("p-4", childrenClassName)}>{children}</div>
        </>
      )}
    </Card>
  )
}

type SharedEntity = {
  group: SharedEntityGroup
}

const TradeRequestDetail = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [, upsertSharedEntity] = useAddUpdateSharedEntityMutation()
  const [{ fetching, data: uData }, upsertTrade] = useUpsertTradeMutation()
  const [, deleteTradeItem] = useDeleteTradeItemMutation()
  const [, deleteExpenseItem] = useDeleteExpenseItemMutation()
  const [, incrementTradeCounter] = useIncrementTradeCounterMutation()
  const { id: sharedDeptId } = useDepartment()
  const [{ data: sData }] = useGetSharedEntityQuery({
    variables: {
      group: SharedEntityGroup.KiteCurrency,
      codename: Codenames.KiteDefaultCurrency,
    },
  })

  const defaultCurrency = sData?.setu_trade_shared_entity?.[0]
  const { id } = useParams()
  const [state, setState] = useState<any>({
    mode: "direct",
    kind: "pro_forma",
    packaging: "raw_material",
    trade_items: [{}],
    start_date: new Date(),
    end_date: null,
    expiry: null,
    expenses: [{}],
  })
  const [isOpen, setOpen] = useState(false)
  const idNum = parseInt(id as any)

  const [removedTradeItems, setRemovedTradeItems] = useState<number[]>([])
  const [removedExpenseItems, setRemovedExpenseItems] = useState<number[]>([])
  const [{ data: dData }] = useTradeDetailQuery({
    variables: { id: idNum },
    pause: !idNum,
    requestPolicy: "network-only",
  })

  const isTradeConfirmed =
    state?.buyer_verification && state?.seller_verification
  const isNewFinalTrade = isTradeConfirmed && Boolean(searchParams.get("new"))
  const isBookingTrade = dData?.trade_by_pk?.kind === "booking"
  // Disable the form is the trade has a receipt associated with it because it will create mismatch in receipt calculations.
  const hasReceipt = dData?.trade_by_pk?.receipt_id
  const isDisabled = hasReceipt
    ? true
    : (isTradeConfirmed || isBookingTrade) && !isNewFinalTrade

  function updateTradeState(
    trade?: TradeDetailQuery["trade_by_pk"],
    bookingGrades?: BookingTradeSummaryQuery
  ) {
    if (!trade) return
    setState({
      ...trade,
      kind: Boolean(searchParams.get("new")) ? "final" : trade.kind,
      buyer:
        isBookingTrade && isNewFinalTrade
          ? undefined
          : { ...trade.buyer?.user[0], shared_id: trade.buyer?.id },
      commodity: {
        ...trade.commodity?.commodity[0],
        shared_id: trade.commodity?.id,
      },
      commodity_variety: {
        ...trade.commodity_variety?.commodity_variety[0],
        shared_id: trade.commodity_variety?.id,
      },
      plot_crop: {
        ...trade.plot_crop?.plot_crop[0],
        shared_id: trade.plot_crop?.id,
      },
      facilitator: {
        ...trade.facilitator?.facilitator[0],
        shared_id: trade.facilitator?.id,
      },
      seller: {
        ...trade?.seller?.user[0],
        shared_id: trade?.seller?.id,
      },
      start_date: trade.start_date && new Date(trade.start_date),
      end_date: trade.end_date && new Date(trade.end_date),
      expiry: trade.expiry && new Date(trade.expiry),
      trade_items: trade.trade_items?.length
        ? trade.trade_items.map(item => ({
            ...item,
            quantity:
              trade.kind === "booking" && searchParams.get("new")
                ? getBookingQuantity(bookingGrades, item.variety_grade?.id)
                : item.quantity,
            quantity_unit: {
              ...item.quantity_unit.si_unit[0],
              shared_id: item.quantity_unit?.id,
            },
            variety_grade: {
              ...item.variety_grade?.variety_grade[0],
              shared_id: item.variety_grade?.id,
            },
          }))
        : [{}],
      expenses: normalizeExpenses(trade.expenses),
    })
  }
  const tradeId = dData?.trade_by_pk?.id

  const [{ data: bookingData }] = useBookingTradeSummaryQuery({
    variables: {
      id: isBookingTrade
        ? tradeId
        : state?.parent_trade?.kind === "booking"
        ? state?.parent_trade?.id
        : undefined,
    },
    pause: !isBookingTrade && state?.parent_trade?.kind !== "booking",
    requestPolicy: "network-only",
  })

  const moreTrades =
    state?.parent_trade?.kind === "booking" || isBookingTrade
      ? bookingData?.trade_grade_quantity_summary_report?.more_trade
      : true

  useEffect(() => {
    if (tradeId) {
      setRemovedTradeItems(() => [])
      updateTradeState(dData?.trade_by_pk, bookingData)
    }
  }, [tradeId, searchParams.get("new"), bookingData])

  useEffect(() => {
    if (uData?.insert_trade_one) {
      setRemovedTradeItems(() => [])
      setRemovedExpenseItems(() => [])
      updateTradeState(uData?.insert_trade_one)
    }
  }, [uData])

  useEffect(() => {
    if (
      dData &&
      sharedDeptId &&
      dData?.trade_by_pk?.department.id !== sharedDeptId
    ) {
      navigate("/d/trade-requests")
    }
  }, [sharedDeptId, dData?.trade_by_pk?.department.reference_id])

  function inputProps(name: string, sharedEntity?: SharedEntity) {
    return {
      value: get(state, name, ""),
      onChange: async (e: any) => {
        const stateCopy = cloneDeep(state)
        let facilitator = stateCopy.seller?.companies?.[0]?.company
        if (name === "mode" && e === "facilitator" && facilitator) {
          const { data: facilitatorShared } = await upsertSharedEntity({
            reference_id: facilitator.codename,
            group_name: SharedEntityGroup.KiteCompany,
            name: facilitator.name,
          })
          let shared_id =
            facilitatorShared?.insert_setu_trade_shared_entity_one?.id
          set(stateCopy, "facilitator", { ...facilitator, shared_id })
        } else if (
          (name === "mode" && e === "direct") ||
          (name === "mode" && e === "facilitator" && !facilitator) ||
          (name === "seller" && !e?.companies.length)
        ) {
          set(stateCopy, "facilitator", null)
          set(stateCopy, "mode", "direct")
        }

        if (sharedEntity && e?.codename && e?.name && !e?.shared_id) {
          const { data } = await upsertSharedEntity({
            reference_id: e.codename,
            group_name: sharedEntity.group,
            name: e.name,
          })
          set(stateCopy, name, {
            ...e,
            shared_id: data?.insert_setu_trade_shared_entity_one?.id,
          })
        } else {
          set(stateCopy, name, e?.target ? e.target.value : e)
        }
        setState(stateCopy)
      },
    }
  }

  const {
    quantity: totalItemsQuantity,
    amount,
    quantity_unit,
  } = state?.trade_items?.reduce(
    (prev, item) => ({
      quantity: (Number(prev.quantity) || 0) + (Number(item.quantity) || 0),
      amount: prev.amount + (item.rate || 0) * (Number(item.quantity) || 0),
      quantity_unit: prev.quantity_unit || item.quantity_unit?.name,
    }),
    { quantity: 0, amount: 0, quantity_unit: "" }
  )
  const finalQuantity =
    totalItemsQuantity -
    totalItemsQuantity * (Number(state?.standard_deduction || 0) / 100)
  const finalAmount =
    amount - amount * (Number(state?.standard_deduction || 0) / 100)

  const [{ data: expenseConfigList }] = useExpenseConfigVersionsQuery({
    variables: {
      department: `${sharedDeptId}`,
      kind: state.kind,
      mode: state.mode,
    },
    pause: !sharedDeptId || !state?.mode || !state?.kind,
    requestPolicy: "network-only",
  })
  const [expenseTemplate, setExpenseTemplate] = useState<any>()

  const [{ data: expenseConfig }] = useExpenseConfigsQuery({
    variables: {
      version: expenseTemplate?.id,
      department: `${sharedDeptId}`,
      kind: state.kind,
      mode: state.mode,
    },
    pause: !expenseTemplate?.id,
    requestPolicy: "network-only",
  })

  useEffect(() => {
    if (!expenseConfig) return
    setState(prev => {
      return {
        ...prev,
        expenses: normalizeExpenses(expenseConfig?.expense_configs || []),
      }
    })
  }, [expenseConfig])

  const [{ data: sellerTncConfigList }] = useTncConfigVersionsQuery({
    variables: {
      department: `${sharedDeptId}`,
      kind: state.kind,
      mode: state.mode,
      role: "seller",
    },
    pause: !sharedDeptId || !state?.mode || !state?.kind,
    requestPolicy: "network-only",
  })
  const [{ data: buyerTncConfigList }] = useTncConfigVersionsQuery({
    variables: {
      department: `${sharedDeptId}`,
      kind: state.kind,
      mode: state.mode,
      role: "buyer",
    },
    pause: !sharedDeptId || !state?.mode || !state?.kind,
    requestPolicy: "network-only",
  })

  const [tncTemplate, setTncTemplate] = useState<{
    buyer?: number
    seller?: number
  }>()

  const [{ data: sellerTncConfig }] = useTncConfigsQuery({
    variables: {
      version: `${tncTemplate?.seller}`,
      department: `${sharedDeptId}`,
      kind: state.kind,
      mode: state.mode,
      role: "seller",
    },
    pause: !tncTemplate?.seller,
    requestPolicy: "network-only",
  })

  const [{ data: buyerTncConfig }] = useTncConfigsQuery({
    variables: {
      version: `${tncTemplate?.buyer}`,
      department: `${sharedDeptId}`,
      kind: state.kind,
      mode: state.mode,
      role: "buyer",
    },
    pause: !tncTemplate?.buyer,
    requestPolicy: "network-only",
  })

  useEffect(() => {
    if (!buyerTncConfig) return
    setState(prev => {
      return {
        ...prev,
        buyer_tnc: buyerTncConfig.trade_tnc_template_detail?.buyer,
      }
    })
  }, [buyerTncConfig])

  useEffect(() => {
    if (!sellerTncConfig) return
    setState(prev => {
      return {
        ...prev,
        seller_tnc: sellerTncConfig.trade_tnc_template_detail?.seller,
      }
    })
  }, [sellerTncConfig])

  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Trade Requests",
              link: "/d/trade-requests",
              isActive: false,
            },
            {
              text: id?.toString() || "New",
              link: `/d/trade-requests/${id}`,
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
        <Link to={`?timeline=${id}`}>Timeline</Link>
      </header>
      <Group className="flex gap-4 px-5 pt-8 pb-16 relative">
        <SideBar
          key={state.id}
          id={
            state?.kind === "final" && state?.parent_trade?.id
              ? state?.parent_trade?.id
              : (isTradeConfirmed &&
                  (state?.kind === "pro_forma" || state?.kind === "booking")) ||
                (state?.kind === "final" && isNewFinalTrade)
              ? state.id
              : undefined
          }
          disableNew={!moreTrades}
          kind={state?.parent_trade?.kind || dData?.trade_by_pk?.kind}
        />
        <div className="w-[60%] mx-auto">
          <FormCard title="Who is trading">
            <div className="flex gap-4">
              <div className={"w-full"}>
                <FormGroup required label="Farmer" className={"mb-2"}>
                  <QuerySelector
                    serverSideQuery
                    dataHook={useUsersQuery}
                    variables={{ group: "Farmer" }}
                    {...inputProps("seller", {
                      group: SharedEntityGroup.KiteUser,
                    })}
                    getOptionLabel={({ is_verified, name }) => {
                      return `${name} | ${
                        is_verified === false
                          ? "Rejected"
                          : is_verified === true
                          ? "Verified"
                          : "Pending Verification"
                      }`
                    }}
                    isDisabled={isDisabled || isBookingTrade}
                  />
                </FormGroup>
                <UserDetailsCard user={state.seller} />
              </div>
              <div className={"w-full"}>
                <FormGroup required={true} label="Trader" className={"mb-2"}>
                  <QuerySelector
                    serverSideQuery
                    dataHook={useUsersQuery}
                    variables={{ group: "Trader" }}
                    {...inputProps("buyer", {
                      group: SharedEntityGroup.KiteUser,
                    })}
                    getOptionLabel={({ is_verified, name }) => {
                      return `${name} | ${
                        is_verified === false
                          ? "Rejected"
                          : is_verified === true
                          ? "Verified"
                          : "Pending Verification"
                      }`
                    }}
                    isDisabled={isDisabled}
                  />
                </FormGroup>
                <UserDetailsCard user={state.buyer} />
              </div>
            </div>
          </FormCard>
          <FormCard title="How is it traded">
            <div className="flex gap-4">
              <div className={"w-full"}>
                <RadioGroup
                  className={"mb-3"}
                  label="Type"
                  required
                  {...inputProps("kind")}
                >
                  {TRADE_KINDS.filter(kind =>
                    kind.id === "booking" ? state.kind === "booking" : true
                  ).map((kind, i) => (
                    <Checkbox
                      key={i}
                      type={"radio"}
                      value={kind.id}
                      label={kind.name}
                      disabled={id !== "new"}
                    />
                  ))}
                </RadioGroup>
                {state.kind === "pro_forma" ? (
                  <Note intent="warning" className="text-yellow-600 text-sm">
                    This is temporary and is subject to change later.
                  </Note>
                ) : (
                  <Note className="text-sm">
                    No information can be changed later. The invoice will be
                    created directly now.
                  </Note>
                )}
              </div>
              <RadioGroup
                className={"w-full"}
                label="Mode"
                required
                {...inputProps("mode")}
              >
                <Checkbox
                  type={"radio"}
                  value="direct"
                  label="Direct"
                  disabled={isDisabled}
                />
                <Checkbox
                  type={"radio"}
                  value="facilitator"
                  label="Via Facilitator"
                  disabled={isDisabled || !state.seller?.companies?.length}
                />
              </RadioGroup>
              {state.mode === "facilitator" ? (
                <FormGroup label="Facilitator Company" className={"w-full"}>
                  <Selector
                    options={state?.seller?.companies?.map(c => c?.company)}
                    {...inputProps("facilitator", {
                      group: SharedEntityGroup.KiteCompany,
                    })}
                    isDisabled={isDisabled}
                  />
                </FormGroup>
              ) : (
                <div className="w-full" />
              )}
            </div>
          </FormCard>
          <FormCard title="What is being trading">
            <div className="flex gap-4">
              <FormGroup required label="Commodity" className={"w-full"}>
                <QuerySelector
                  serverSideQuery
                  dataHook={useAllCommoditiesQuery}
                  {...inputProps("commodity", {
                    group: SharedEntityGroup.KiteCommodity,
                  })}
                  isDisabled={isDisabled || isBookingTrade}
                />
              </FormGroup>{" "}
              <FormGroup required label="Variety" className={"w-full"}>
                <QuerySelector
                  dataHook={useAllVarietiesQuery}
                  variables={{ commodity_id: state.commodity?.id }}
                  {...inputProps("commodity_variety", {
                    group: SharedEntityGroup.KiteVariety,
                  })}
                  isDisabled={isDisabled || isBookingTrade}
                />
              </FormGroup>{" "}
              <FormGroup label="Plot Selection" className={"w-full"}>
                <QuerySelector
                  dataHook={useAllPlotCropsQuery}
                  variables={{
                    farmer: state.seller?.id,
                    crop: state.commodity?.crop_id,
                  }}
                  {...inputProps("plot_crop", {
                    group: SharedEntityGroup.KitePlotCrop,
                  })}
                  getOptionLabel={({
                    tag,
                    name,
                    external_reference_id,
                    id,
                  }) => {
                    if (!id) return undefined
                    return `${tag} ${name} | ${external_reference_id}`
                  }}
                  isDisabled={isDisabled}
                />
              </FormGroup>
            </div>
            <div className="mt-2">
              <RadioGroup
                label="Packaging type"
                required
                {...inputProps("packaging")}
              >
                <Checkbox
                  type={"radio"}
                  value="raw_material"
                  label="Raw Material"
                  disabled={isDisabled}
                />
                <Checkbox
                  type={"radio"}
                  value="finished_goods"
                  label="Finished Goods"
                  disabled={isDisabled}
                />
              </RadioGroup>
            </div>
          </FormCard>
          <FormCard title="When is it traded">
            <div className={"flex gap-4"}>
              <FormGroup required label="Start Date of Trade">
                <DateInput
                  {...inputProps("start_date")}
                  disabled={isDisabled}
                  popoverPanelClassName={classNames(isDisabled ? "hidden" : "")}
                />
              </FormGroup>
              <FormGroup label="End Date of Trade">
                <DateInput
                  {...inputProps("end_date")}
                  disabled={isDisabled}
                  popoverPanelClassName={classNames(isDisabled ? "hidden" : "")}
                />
              </FormGroup>
              {!isNewFinalTrade ? (
                <FormGroup label="Validity of Trade" required>
                  <DateInput
                    {...inputProps("expiry")}
                    disabled={isDisabled}
                    popoverPanelClassName={classNames(
                      isDisabled ? "hidden" : ""
                    )}
                  />
                </FormGroup>
              ) : null}
            </div>
          </FormCard>
          <FormCard title="Trade Particulars">
            <div className="mb-3 border rounded-lg">
              <header className="flex justify-between items-center p-2">
                <h6>Add Multiple Grades</h6>
                {isDisabled || isBookingTrade ? null : (
                  <Button
                    onClick={() => {
                      setState(prev => ({
                        ...prev,
                        trade_items: prev.trade_items?.length
                          ? [
                              {
                                quantity: 0,
                                variety_grade: null,
                                rate: 0,
                                rate_unit: prev.trade_items?.[0]?.rate_unit,
                                quantity_unit:
                                  prev.trade_items?.[0]?.quantity_unit,
                              },
                              ...prev.trade_items,
                            ]
                          : [{}],
                      }))
                    }}
                    size="sm"
                    leftIcon={<Plus />}
                  >
                    Grade
                  </Button>
                )}
              </header>
              <hr />
              <div className="p-3 border-b">
                {state?.trade_items?.map((item, index, allItems) => {
                  let itemRemoved = removedTradeItems.includes(item.id)
                  let remainingQuantity = getBookingQuantity(
                    bookingData,
                    item?.variety_grade?.shared_id
                  )

                  return (
                    <div key={index} className="flex gap-4 mb-4">
                      <FormGroup label="Grade" className="flex-1">
                        <QuerySelector
                          dataHook={useAllVarietyGradesQuery}
                          variables={{ variety: state?.commodity_variety?.id }}
                          {...inputProps(`trade_items.${index}.variety_grade`, {
                            group: SharedEntityGroup.KiteGrade,
                          })}
                          isDisabled={
                            isDisabled || itemRemoved || isBookingTrade
                          }
                          isOptionDisabled={(opt: any) =>
                            state?.trade_items?.find(
                              item => item.variety_grade?.id === opt.id
                            )
                          }
                        />
                      </FormGroup>
                      <FormGroup label="Rate" className="flex-1">
                        <InputGroup
                          inputProps={{
                            ...inputProps(`trade_items.${index}.rate`),
                            type: "number",
                            className: "text-right",
                          }}
                          disabled={
                            isDisabled ||
                            itemRemoved ||
                            (isBookingTrade && !remainingQuantity)
                          }
                          className="self-end w-full"
                          rightElement={
                            <Tag className="mr-2" variant="minimal">
                              {defaultCurrency?.name || "INR"}
                            </Tag>
                          }
                        />
                      </FormGroup>
                      <FormGroup
                        label={
                          state?.parent_trade?.kind === "booking" ||
                          (isBookingTrade && isNewFinalTrade)
                            ? `Quantity (Max: ${remainingQuantity})`
                            : "Quantity"
                        }
                        className="flex-1"
                      >
                        <InputGroup
                          inputProps={{
                            ...inputProps(`trade_items.${index}.quantity`),
                            type: "number",
                            className: "text-right mr-24",
                            onChange: e => {
                              let value = Number(e.target.value)
                              let original = Number(remainingQuantity)
                              let result =
                                (state?.parent_trade?.kind === "booking" ||
                                  (isBookingTrade && isNewFinalTrade)) &&
                                value > original
                                  ? original
                                  : value
                              inputProps(
                                `trade_items.${index}.quantity`
                              ).onChange(result)
                            },
                          }}
                          disabled={
                            isDisabled ||
                            itemRemoved ||
                            (isBookingTrade && !remainingQuantity)
                          }
                          rightElement={
                            <QuerySelector
                              components={{
                                IndicatorSeparator: () => null,
                                ClearIndicator: () => null,
                              }}
                              getControlStyles={prev => {
                                return {
                                  ...prev,
                                  border: "none",
                                }
                              }}
                              dataHook={useAllSiUnitsQuery}
                              variables={{ type: ["Weight", "SKU"] }}
                              isDisabled={
                                isDisabled ||
                                (isBookingTrade && !remainingQuantity) ||
                                itemRemoved ||
                                index > 0
                              }
                              {...inputProps(`trade_items.${0}.quantity_unit`, {
                                group: SharedEntityGroup.KiteSiUnit,
                              })}
                            />
                          }
                        />
                      </FormGroup>
                      <FormGroup
                        label="Total Cost"
                        className="flex-[0.75] text-right"
                      >
                        <Text className="flex-1 flex items-center justify-end">
                          {inr((item.quantity || 0) * (item.rate || 0))}
                        </Text>
                      </FormGroup>
                      <div className="w-10 flex self-center justify-center">
                        {state.kind === "booking" ? null : !isTradeConfirmed ? (
                          <button
                            className="text-red-500 pt-5"
                            onClick={async () => {
                              if (item.id) {
                                await deleteTradeItem({ id: item.id })
                              }
                              setState(prev => {
                                const newTradeItems = prev.trade_items?.filter(
                                  (_, i) => i !== index
                                )
                                return {
                                  ...prev,
                                  trade_items: newTradeItems?.length
                                    ? newTradeItems
                                    : [{}],
                                }
                              })
                            }}
                          >
                            <Trash height={24} width={24} />
                          </button>
                        ) : isNewFinalTrade ? (
                          <Checkbox
                            checked={itemRemoved}
                            onChange={() =>
                              setRemovedTradeItems(items =>
                                !itemRemoved
                                  ? [...items, item.id]
                                  : items.filter(itm => itm !== item.id)
                              )
                            }
                          >
                            {itemRemoved ? (
                              <X
                                className="absolute top-0 left-0 text-white bg-red-400 rounded"
                                size={20}
                              />
                            ) : null}
                          </Checkbox>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div>
                <Group className="flex items-center gap-4 p-3 border-b">
                  <Text className="flex-[2_1_0%]">Total</Text>
                  <Text className="flex-1 text-right">
                    {Number(totalItemsQuantity).toFixed(1)} {quantity_unit}
                  </Text>
                  <Text className="flex-[0.75] text-right">{inr(amount)}</Text>
                  <span className="w-10" />
                </Group>
                <Group className="flex items-center gap-4 p-3 border-b">
                  <Group className="flex gap-2 items-center flex-1">
                    <Text>Standard Deduction</Text>
                  </Group>
                  <Group className="flex gap-2 items-center flex-1">
                    <InputGroup
                      inputProps={{
                        ...inputProps("standard_deduction"),
                        max: 100,
                        min: 0,
                        type: "number",
                        placeholder: "Value in %",
                        className: "text-right",
                      }}
                      className="flex-1"
                      disabled={isDisabled}
                      rightElement={<span className="mr-2">%</span>}
                    />
                  </Group>
                  <Text className="flex-1 text-right">
                    {Number(
                      totalItemsQuantity *
                        (Number(state?.standard_deduction || 0) / 100)
                    ).toFixed(1)}{" "}
                    {quantity_unit}
                  </Text>
                  <Text className="flex-[0.75] text-right">
                    {inr(
                      amount * (Number(state?.standard_deduction || 0) / 100)
                    )}
                  </Text>
                  <span className="w-10" />
                </Group>
                <Group className="flex items-center gap-4 p-3 bg-gray-200">
                  <Text className="flex-[2_1_0%]">Final</Text>
                  <Text className="flex-1 text-right">
                    {Number(finalQuantity).toFixed(1)} {quantity_unit}
                  </Text>
                  <Text className="flex-[0.75] text-right">
                    {inr(finalAmount)}
                  </Text>
                  <span className="w-10" />
                </Group>
              </div>
            </div>
          </FormCard>
          <FormCard
            title="Trade Expenses"
            action={
              isDisabled ? null : (
                <>
                  <Selector
                    value={expenseTemplate}
                    options={expenseConfigList?.expense_config_versions?.map(
                      item => ({ id: item?.version_id, name: item?.name })
                    )}
                    className="ml-auto mr-8 font-400"
                    onChange={(value: any) => setExpenseTemplate(value)}
                  />
                  <Button
                    onClick={() => {
                      setState(prev => {
                        return {
                          ...prev,
                          expenses: prev.expenses?.length
                            ? [{}, ...prev.expenses]
                            : [{}],
                        }
                      })
                    }}
                    leftIcon={<Plus />}
                    size="sm"
                  >
                    Expense
                  </Button>
                </>
              )
            }
          >
            <table className="w-full expenses-table flex flex-col">
              <thead className="bg-green-100 text-sm rounded overflow-hidden">
                <tr className="flex">
                  <td className="p-1 flex-[3]">Expense Type</td>
                  <td className="p-1 flex-1">Farmer Charge</td>
                  <td className="p-1 flex-1">Trader Charge</td>
                  <td className="p-1 flex-1 text-right">Expense Amount</td>
                  {isDisabled ? null : <td className="w-10"></td>}
                </tr>
              </thead>
              <tbody>
                {state?.expenses?.map((expense, idx) => {
                  const rightElement = expense?.kind?.short && (
                    <Tag variant="minimal" className="mr-2">
                      {expense?.kind?.short}
                    </Tag>
                  )
                  let sellerValue = inputProps(`expenses.${idx}.seller.value`)
                  let buyerValue = inputProps(`expenses.${idx}.buyer.value`)
                  return (
                    <tr key={idx} className="flex items-center">
                      <td className="flex gap-3 flex-[3]">
                        <QuerySelector
                          className="w-full"
                          isOptionDisabled={(opt: any) =>
                            state?.expenses?.find(
                              exp =>
                                Number(exp.expense_parameter?.id) ===
                                Number(opt.id)
                            )
                          }
                          isDisabled={isDisabled}
                          dataHook={useExpenseParametersQuery}
                          {...inputProps(`expenses.${idx}.expense_parameter`)}
                        />
                        <Selector
                          className="w-full"
                          options={EXPENSE_KINDS}
                          isDisabled={isDisabled}
                          {...inputProps(`expenses.${idx}.kind`)}
                        />
                      </td>
                      <td className="flex-1">
                        <InputGroup
                          inputProps={{
                            ...sellerValue,
                            type: "number",
                            className: "text-right",
                          }}
                          disabled={isDisabled}
                          rightElement={rightElement}
                          className="text-right"
                        />
                      </td>
                      <td className="flex-1">
                        <InputGroup
                          inputProps={{
                            ...buyerValue,
                            type: "number",
                            className: "text-right",
                          }}
                          disabled={isDisabled}
                          rightElement={rightElement}
                        />
                      </td>
                      <td className="text-right flex-1">
                        {inr(
                          expenseAmount({
                            kind: expense?.kind?.id,
                            buyerAmount: Number(buyerValue.value) || 0,
                            sellerAmount: Number(sellerValue.value) || 0,
                            finalAmount: amount,
                            finalQuantity,
                            totalQuantity: totalItemsQuantity,
                          })
                        )}
                      </td>
                      {isDisabled ? null : (
                        <button
                          className="text-red-500 w-10 flex justify-center"
                          onClick={() => {
                            setRemovedExpenseItems(prev => [
                              ...prev,
                              ...(expense?.buyer?.id
                                ? [expense?.buyer?.id]
                                : []),
                              ...(expense?.seller?.id
                                ? [expense?.seller?.id]
                                : []),
                            ])
                            setState(prev => ({
                              ...prev,
                              expenses: prev.expenses.filter(
                                (_, i) => i !== idx
                              ),
                            }))
                          }}
                        >
                          <Trash height={24} width={24} className="!mr-0" />
                        </button>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </FormCard>
          <FormCard title="Terms and Conditions" childrenClassName="flex gap-4">
            <FormGroup
              labelProps={{ className: "text-base" }}
              className={"w-full"}
              label={
                <div className="flex justify-between items-center">
                  <span>Farmer Terms</span>
                  {isDisabled ? null : (
                    <Selector
                      options={sellerTncConfigList?.trade_tnc_versions?.map(
                        item => ({
                          id: item?.id,
                          name: item?.name,
                        })
                      )}
                      className="ml-auto mr-8 font-400"
                      onChange={(value: any) =>
                        setTncTemplate({ seller: value?.id })
                      }
                    />
                  )}
                </div>
              }
            >
              <Editor
                apiKey={import.meta.env.VITE_EDITOR_KEY}
                value={state?.seller_tnc}
                onEditorChange={value => {
                  setState(prev => ({
                    ...prev,
                    seller_tnc: value,
                  }))
                }}
                disabled={isDisabled}
              />
            </FormGroup>
            <FormGroup
              labelProps={{ className: "text-base" }}
              className={"w-full"}
              label={
                <div className="flex justify-between items-center">
                  <span>Trader Terms</span>
                  {isDisabled ? null : (
                    <Selector
                      options={buyerTncConfigList?.trade_tnc_versions?.map(
                        item => ({
                          id: item?.id,
                          name: item?.name,
                        })
                      )}
                      className="ml-auto mr-8 font-400"
                      onChange={(value: any) =>
                        setTncTemplate({ buyer: value?.id })
                      }
                    />
                  )}
                </div>
              }
            >
              <Editor
                apiKey={import.meta.env.VITE_EDITOR_KEY}
                value={state?.buyer_tnc}
                onEditorChange={value => {
                  setState(prev => ({
                    ...prev,
                    buyer_tnc: value,
                  }))
                }}
                disabled={isDisabled}
              />
            </FormGroup>
          </FormCard>
        </div>
      </Group>
      <footer className="fixed bg-white left-0 bottom-0 w-full p-2 shadow-sm flex justify-end gap-4">
        {moreTrades && isDisabled && state.kind !== "final" && !hasReceipt ? (
          <Button
            onClick={() => setSearchParams({ new: "true" })}
            className="!bg-cyan-400"
            leftIcon={<CheckCircle />}
          >
            Generate Hishob Patti
          </Button>
        ) : null}
        {isDisabled ? null : (
          <Button
            loading={fetching}
            onClick={async () => {
              const std = Number(state?.standard_deduction)
              if (removedExpenseItems.length)
                await deleteExpenseItem({ ids: removedExpenseItems })
              const finalQuantity = state?.trade_items?.[0]?.quantity_unit
              const { data: iData, error } = await upsertTrade({
                input: {
                  id: id === "new" || isNewFinalTrade ? undefined : state?.id,
                  parent_trade_id: isNewFinalTrade ? state?.id : null,
                  packaging: state?.packaging,
                  kind: isNewFinalTrade ? "final" : state?.kind,
                  mode: state?.mode,
                  standard_deduction: std ?? null,
                  buyer_tnc: state?.buyer_tnc,
                  seller_tnc: state?.seller_tnc,
                  current_status_id: "Draft",
                  start_date:
                    state?.start_date &&
                    dayjs(state?.start_date).format("YYYY-MM-DD"),
                  end_date:
                    state?.end_date &&
                    dayjs(state?.end_date).format("YYYY-MM-DD"),
                  expiry:
                    state?.expiry && dayjs(state?.expiry).format("YYYY-MM-DD"),
                  facilitator_id: state?.facilitator?.shared_id,
                  department_id: sharedDeptId,
                  commodity_id: state?.commodity?.shared_id,
                  commodity_variety_id: state?.commodity_variety?.shared_id,
                  plot_crop_id: state?.plot_crop?.shared_id,
                  seller_id: state?.seller?.shared_id,
                  buyer_id: state?.buyer?.shared_id,
                  trade_amounts_counter: 0,
                  trade_items: state?.trade_items?.filter(i => !isEmpty(i))
                    ?.length
                    ? {
                        data: state?.trade_items
                          ?.filter(item => !removedTradeItems.includes(item.id))
                          .map(item => ({
                            ...(!isNewFinalTrade && { id: item.id }),
                            rate: item.rate,
                            rate_unit_id: finalQuantity?.shared_id,
                            quantity: item.quantity,
                            quantity_unit_id: finalQuantity?.shared_id,
                            variety_grade_id: item.variety_grade?.shared_id,
                            currency_id: defaultCurrency?.id,
                          })),
                        on_conflict: {
                          constraint: Trade_Item_Constraint.TradeItemPkey,
                          update_columns: [
                            Trade_Item_Update_Column.VarietyGradeId,
                            Trade_Item_Update_Column.Rate,
                            Trade_Item_Update_Column.RateUnitId,
                            Trade_Item_Update_Column.Quantity,
                            Trade_Item_Update_Column.QuantityUnitId,
                            Trade_Item_Update_Column.CurrencyId,
                          ],
                        },
                      }
                    : undefined,
                  expenses: state?.expenses?.filter(i => i.seller || i.buyer)
                    ?.length
                    ? {
                        data: state?.expenses?.reduce((prev, expense, idx) => {
                          const commonProperties = {
                            expense_parameter_id: expense.expense_parameter?.id,
                            kind: expense.kind?.id,
                          }
                          if (expense.buyer?.value) {
                            prev.push({
                              ...commonProperties,
                              ...(!isNewFinalTrade && { id: expense.buyer.id }),
                              paid_by: "buyer",
                              value: expense.buyer.value,
                            })
                          }
                          if (expense.seller?.value) {
                            prev.push({
                              ...commonProperties,
                              ...(!isNewFinalTrade && {
                                id: expense.seller.id,
                              }),
                              paid_by: "seller",
                              value: expense.seller.value,
                            })
                          }
                          return prev
                        }, []),
                        on_conflict: {
                          constraint: Expense_Constraint.ExpensePkey,
                          update_columns: [
                            Expense_Update_Column.ExpenseParameterId,
                            Expense_Update_Column.Kind,
                            Expense_Update_Column.PaidBy,
                            Expense_Update_Column.Value,
                          ],
                        },
                      }
                    : undefined,
                },
              })
              if (error || !iData) {
                return toast.custom(
                  <Toast
                    title={"Unable to create the trade request"}
                    intent="danger"
                  />
                )
              }
              if (iData?.insert_trade_one?.id) {
                await incrementTradeCounter({ id: iData.insert_trade_one.id })
                toast.custom(<Toast title={"Trade created successfully!"} />)
                navigate(`/d/trade-requests/${iData?.insert_trade_one?.id}`)
                setOpen(true)
              }
            }}
            leftIcon={<CheckCircle />}
          >
            {state?.id ? "Save" : "Create"}{" "}
            {state?.kind === "final" || isNewFinalTrade
              ? "Hishob Patti"
              : "Sauda Pavti"}
          </Button>
        )}
      </footer>
      {id && id !== "new" && (
        <SendConsent
          kind={state.kind}
          buyer={state.buyer}
          seller={state.seller}
          tradeId={id}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

export default TradeRequestDetail
